<template>
    <div v-loading.fullscreen.lock="true" element-loading-text="登陆中..." class=""></div>
</template>
<script>
    export default {
        data() {
            return {

            }
        },
        mounted() {
            //localStorage.setItem('token1','7a60c247-c278-4f43-8b93-004c129ee56e');
            //this.$router.replace('/course');
            let token = localStorage.getItem("token1")??"";

            //this.$message.error(token);
            //判断来源
            if (this.$isWeixin()) {
                //this.$message.error('微信环境');
                //是手机的话走微信授权登陆
                //console.log('weixin');
                //this.$router.replace('/bind');

                //判断本次缓存
                let cacheSn = localStorage.getItem("sn") ?? "";

                if (token) {
                    this.$router.replace('/course?sn='+cacheSn);
                } else {
                    this.$router.replace('/bind?sn=' + cacheSn);
                }


            } else {
                //不是手机扫码登陆
                //console.log('非微信');
                //this.$message.error('非微信环境');
                if (!token) {
                    this.$router.replace('/login');
                    return;
                }
                this.$router.replace('/course');
                //this.$router.replace('/login');
            }
        },
    }
</script>